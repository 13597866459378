.thank-you-body {
  padding: 24px;
  flex-direction: column;
}

.board-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.board {
  display: grid;
  grid-gap: 8px;
  margin: 24px;
}

.tooltip-wrapper {
  display: flex;
  justify-content: center;
  position: relative;
}

.error-tooltip {
  display: none;
  font-size: 1em;
  text-align: center;
  background-color: rgba(97, 97, 97, 0.92);
  border-radius: 4px;
  color: #fff;
  font-family: "Open Sans Condensed",sans-serif;
  padding: 4px 8px;
  max-width: 300px;
  margin: 2px;
  word-wrap: break-word;
  font-weight: 500;
  position: absolute;
  bottom: -40px;
  z-index: 100;
}

.row {
  display: flex;
  grid-gap: 8px;
}

@media (prefers-reduced-motion: no-preference) {
  .row-shake {
    animation: shake infinite 0.25s alternate;
  }
}

@keyframes shake {
  from {
    transform: translateX(0px);
  }
  to {
    transform: translateX(10px);
  }
}

.box {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 6vw;
  max-height: 60px;
  min-height: 44px;
  width: 5vw;
  max-width: 60px;
  min-width: 44px;
  border: 2px solid #d3d6da;
  font-family: sans-serif;
  font-size: x-large;
  text-transform: uppercase;
  transform: rotate3d(1, 1, 1, 0deg);
  background-color: unset;
}

.guess {
  transform: rotate3d(1, 1, 1, 360deg);
}

.absent {
  background-color: #6e6e70;
  border: 2px solid #6e6e70;
}

.present {
  background-color: #b59f3b;
  border: 2px solid #b59f3b;
}

.goteem {
  background-color: #538d4e;
  border: 2px solid #538d4e;
}

.keyboard {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 1vh;
}

.keyboard-disabled {
  pointer-events: none;
}

.keyboard-row {
  display: flex;
  text-transform: uppercase;
  margin: 4px 0;
}

.placeholder {
  font-family: monospace;
  font-weight: 200;
  font-size: xx-large;
  text-transform: uppercase;
}

.blurry-text {
    text-shadow: 0 0 16px white;
    color: transparent;
    transition: text-shadow 3s;
    font-style: italic;
}

.unblur {
  text-shadow: 0 0 0px white;
}

@media (max-width: 800px) {
  .box h2 {
    font-size: 1.25em;
  }
}