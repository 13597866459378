* {
  box-sizing: border-box;
}

.App {
  font-family: 'Open Sans Condensed', sans-serif;
}

.App-splash {
  height: 40vmin;
  pointer-events: none;
}

.App-main {
  display: flex;
  background-color: #282c34;
  min-height: 100vh;
  color: white;
}

.App-left {
  flex-shrink: 0;
  min-height: 100vh;
  width: 40%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  box-shadow: 6px 0 6px -4px white
}

.App-header, .App-skinny-header {
  text-align: center;
  width: 100%;
  font-family: 'Corinthia', cursive;
}

.App-skinny-header {
  display: none;
}

.App-nav-skinny {
  display: none;
}

.App-right {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-height: 100vh;
  overflow-y: auto;
  padding: 0 8vmin 6vmin 8vmin;
  font-size: calc(10px + 2vmin);
}

@media (max-width: 640px) {
  .App-main {
    flex-direction: column;
  }

  .App-header {
    display: none;
  }

  .App-skinny-header {
    display: block;
  }

  .App-nav {
    display: none;
  }

  .App-nav-skinny {
    display: block;
  }

  .App-left {
    min-height: 30vh;
    width: 100%;
    background-position: unset;
    box-shadow: 0 6px 6px -4px white
  }

  .App-right {
    overflow-y: unset;
    height: 100%;
    max-height: 100%;
  }
}

.App-section, .App-last-section {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 4vmin 0;
  max-width: 512px;
  position: relative;
}

.App-section > *, .App-last-section > * {
  margin-top: 0;
  margin-bottom: 1em;
}

.App-section::after {
  content: "";
  align-self: center;
  width: 70%;
  border-bottom: 1px solid white;
  padding-top: 6vmin;
}

.Rsvp-section {
  margin: 4vmin 0;
}

.Rsvp-row {
  flex-direction: row;
}

.Rsvp-name {
  width: 100%;
}

.App-link {
  color: #61dafb;
}
